<template>
  <div>
    <el-row>
      <el-col>
        <el-button
          type="primary"
          icon="CirclePlus"
          @click="addHospitalsShow = true"
        >
          添加院系
        </el-button>
      </el-col>
    </el-row>

    <!-- 表格数据 -->

    <el-table
      :data="hospitalList"
      border
      style="width: 100%; margin-top: 10px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="name" label="院系名称" />

      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="是否删除该院系?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 添加院系 -->
    <add-hospitals
      v-model="addHospitalsShow"
      :editorial="editorial"
      :onhospitalList="onhospitalList"
    >
    </add-hospitals>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import addHospitals from './components/addEditHospitals.vue'
import { ElMessage } from 'element-plus'
import { hospitalListAPI, deletedepartmentAPI } from '@/api/colleges'
/**
 *  表单 S
 */
const search = ref({
  type: '',
  search: '',
  page: 1,
  limit: 10,
  total: 0
})

/**
 *  表单 E
 */

/**
 *  表格 S
 */
// 院系列表数据
const hospitalList = ref([])

// 院系列表
const onhospitalList = async () => {
  const { data, total } = await hospitalListAPI(search.value)
  hospitalList.value = data
  search.value.total = total
}
onhospitalList()

// 编辑院系
const editorial = ref({})

const onEditBtn = (row) => {
  editorial.value = row
  addHospitalsShow.value = true
}

const deleteBtn = async (id) => {
  await deletedepartmentAPI({ id })
  ElMessage.success('删除院系成功。')
  onhospitalList()
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onhospitalList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onhospitalList()
}

/**
 *  分页 E
 */

/**
 *  弹窗组件 S
 */

const addHospitalsShow = ref(false)

/**
 *  弹窗组件
 */
</script>

<style lang="scss" scoped></style>
