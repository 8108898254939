import request from '@/utils/request'

// 院系列表
export const hospitalListAPI = (params) => {
  return request({
    url: '/collegeList',
    method: 'GET',
    params
  })
}

// 添加院系
export const addingHospitalsAPI = (data) => {
  return request({
    url: '/addCollege',
    method: 'POST',
    data
  })
}

// 删除院系
export const deletedepartmentAPI = (params) => {
  return request({
    url: '/collegeDel',
    method: 'GET',
    params
  })
}

// 修改院系
export const modifyTheHospitalAPI = (data) => {
  return request({
    url: '/collegeSave',
    method: 'POST',
    data
  })
}
