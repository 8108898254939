<template>
  <el-dialog
    v-model="addHospitalsShow"
    @close="handleClose"
    :title="addForm.id ? '修改院系' : '添加院系'"
    width="30%"
  >
    <el-form
      label-width="100px"
      :model="addForm"
      :rules="addFormRules"
      ref="hospitalFormRef"
    >
      <el-row>
        <el-col :span="20">
          <el-form-item label="院系名" prop="name">
            <el-input placeholder="请输入新增院系名" v-model="addForm.name" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose"> 取消</el-button>
        <el-button type="primary" @click="onOkBtn"> 确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { addingHospitalsAPI, modifyTheHospitalAPI } from '@/api/colleges'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'
/**
 *  v-model
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  editorial: {
    type: Object
  },

  onhospitalList: {
    type: Function
  }
})
defineEmits(['update:modelValue', 'editorial']) // 注册 v-model 事件
const addHospitalsShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

/**
 *  v-model
 */

watch(
  () => props.editorial,
  (val) => {
    addForm.value = val
  }
)

/**
 *  表单 S
 */
// 表单数据源
const addForm = ref({
  name: ''
})

// 验证规则
const addFormRules = ref({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '院系名必填'
    }
  ]
})

// 取消
const handleClose = () => {
  addHospitalsShow.value = false
  hospitalFormRef.value.resetFields()
  addForm.value = {}
  props.onhospitalList()
}

// 确认
// 获得 form 实例
const hospitalFormRef = ref(null)
const onOkBtn = () => {
  hospitalFormRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 触发添加 api
      if (addForm.value.id) {
        await modifyTheHospitalAPI(addForm.value)
        ElMessage.success('编辑院系成功')
      } else {
        await addingHospitalsAPI(addForm.value)
        ElMessage.success('添加院系成功')
      }
      props.onhospitalList()
    } catch (error) {
      console.log(error)
    } finally {
      addHospitalsShow.value = false
    }
  })
}

/**
 *  表单 E
 */
</script>

<style lang="scss" scoped></style>
